/*********************************************************************************

	Template Name: Trydo React Degital Agency Template
	Note: This is style css.

**********************************************************************************/

/**************************************************************
	
	STYLESHEET INDEXING
	|
	|
	|___ Default Styles
	|	|
	|	|___ variables
	|	|___ mixins
	|	|___ reset
	|	|___ typography
	|	|___ extend
	|	|___ animations
	|	|___ shortcode
	|	|___ spacing
	|
	|
	|___Header Styles
	|	|___ header
	|	|___ mainmenu
	|
	|
	|___Inner Styles
	|	|___ breadcrump 
	|	|___ section-title 
	|	|___ button
	|	|___ pagination
	|	|___ socialicon
	|	|___ footer
	|
	|___Elements Styles
	|	|___ portfolio 
	|	|___ service 
	|	|___ slider 
	|	|___ counterup 
	|	|___ testimonial 
	|	|___ brand 
	|	|___ team 
	|	|___ callto-action 
	|	|___ tab 
	|	|___ accordion 
	|	|___ list 
	|	|___ contact 
	|
	|___BLog Styles
	|	|___ blog 
	|	|___ blog-details 
	|
	|___Template Styles
	|	|___ about 
	|	|___ portfolio-details
	|	|___ service-details
	|	|___ preview
	|
	|
	|___ END STYLESHEET INDEXING

***************************************************************/


/**************************************
    Default Styles
***************************************/
@import 'default/variables';
@import 'default/mixins';
@import 'default/reset';
@import 'default/typography';
@import 'default/extend';
@import 'default/animations';
@import 'default/shortcode';
@import 'default/spacing';



/**************************************
    Header Styles
***************************************/

@import 'header/header';
@import 'header/mainmenu';


/**************************************
    Inner Styles
***************************************/
@import 'common/button';
@import 'common/breadcrump';
@import 'common/video-popup';
@import 'common/section-title';
@import 'common/pagination';
@import 'common/socialicon';



/**************************************
    Elements Styles
***************************************/

@import 'elements/portfolio';
@import 'elements/service';
@import 'elements/slider';
@import 'elements/counterup';
@import 'elements/testimonial';
@import 'elements/brand';
@import 'elements/team';
@import 'elements/callto-action';
@import 'elements/tab';
@import 'elements/accordion';
@import 'elements/list';
@import 'elements/contact';
@import 'elements/progress';
@import 'elements/column';
@import 'elements/404';
@import 'elements/pricing';



/**************************************
    Blog Styles
***************************************/

@import 'blog/blog';
@import 'blog/blog-details';

/**************************************
    Footer Styles
***************************************/
@import 'footer/footer';





/**************************************
    Template Styles
***************************************/

@import 'template/about';
@import 'template/portfolio-details';
@import 'template/service-details';
@import 'template/dark-version';
@import 'template/preview';

.language-selector {
  position: fixed;
  right: 50px;
  top: 0;
  z-index: 50000;
  display: block;
  width: 100%;
  height: 30px;

  &__item {
    color: #fff !important;
    margin: 0 0 0 30px;
    padding: 3px 10px;
    position: relative;
    cursor: pointer;

    &_active {
      background: linear-gradient(120deg, #1C99FE 20.69%, #7644FF 50.19%, var(--color-primary) 79.69%);
    }

    &:after {
      content: '|';
      position: absolute;
      bottom: 0;
      right: -15px;
    }

    &:last-child {
      &:after {
        display: none;
      }
    }
  }
}
